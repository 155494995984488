import axios from '@/axios.js'

export default {
  fetchBrands ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/hotels')
        .then(({data: response}) => {
          commit('SET_BRANDS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchBrand (context, brandId) {
    return new Promise((resolve, reject) => {
      axios.get(`/hotels/${brandId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeBrand (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/hotels', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateBrand (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/hotels/${payload.id}`, payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateMapCode(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/hotels/${payload.id}/codes`, payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateActiveProvider(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/hotels/${payload.id}/provider`, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  updateMarkup(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/hotels/${payload.id}/markup`, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  destroyBrand ({ commit }, brandId) {
    return new Promise((resolve, reject) => {
      axios.delete(`hotels/${brandId}`)
        .then((response) => {
          commit('REMOVE_RECORD', brandId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
