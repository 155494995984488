import axios from "@/axios.js";

export default {
  async fetchSitemaps({ commit }) {
    try {
      const { data } = await axios.get(`/sitemap`);
      commit("setSitemaps", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSitemap({ commit }, params) {
    try {
      const { data } = await axios.get(`/sitemap/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeSitemap({ commit }, params) {
    try {
      const { data } = await axios.post(`/sitemap`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateSitemap({ commit }, params) {
    try {
      const { data } = await axios.post(`/sitemap/${params.id}`, params.body);
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeSitemap({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/sitemap/${params}`);
      dispatch("fetchSitemaps");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
